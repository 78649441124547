<template>
  <!--Absolutely necessary in a vuetify-based app-->
  <v-app>
    <!-- Menu: where we can choose where to go -->
    <!-- NB: we have to put it before the toolbar so it's prettier-->
    <vinx-drawer
      :vinx-drawer-open="appDrawerOpen"
      @vinx-menu-off="appDrawerOpen = false"
    />

    <!-- Toolbar: the bar on top of everything -->
    <vinx-toolbar @drawer-toggled="appDrawerOpen = !appDrawerOpen" />

    <!-- Content: depends on the current route. NB: this is idiomatic vue-router code! -->
    <v-main>
      <router-view :key="$route.path" />
    </v-main>

    <!-- Footer -->
    <vinx-footer />

    <!-- Notifications, with vue-snotify -->
    <vue-snotify />

  </v-app>
</template>

<script>
import { VApp, VMain } from "vuetify/lib";
import vinxDrawer from "./VINXDrawer.vue";
import vinxToolbar from "./VINXToolbar.vue";
import vinxFooter from "./VINXFooter.vue";
// import { mapGetters } from "vuex";
// import { get } from "@/store/constants";
// import path from "../path";

export default {
  //   // name: 'App',
  components: {
    VApp,
    VMain,
    vinxDrawer,
    vinxToolbar,
    vinxFooter,
  },
  //   computed: {
  //     ...mapGetters({
  //       user: get.CURRENT_USER
  //     })
  //   },
  data() {
    return {
      appDrawerOpen: false,
    };
  },
  //   watch: {
  //     // if the user info becomes unavailable, then we show the login page
  //     user (newVal, oldVal) {
  //       if (newVal == null && oldVal != null) {
  //         console.log('User', oldVal.firstName, '', oldVal.lastName, 'is not logged in anymore!')
  //         if (!this.$route.fullPath.startsWith(path.LOGIN)) {
  //           this.$router.push({ path: path.LOGIN, query: { next: this.$route.fullPath } })
  //         }
  //       }
  //     }
  //   }
};
</script>

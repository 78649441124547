<template>
  <v-navigation-drawer
    v-model="drawerOpen"
    width="width"
    app
    fixed
    :clipped="$vuetify.breakpoint.lgAndUp"
    style="transition-duration: 0.1s"
  >
    <v-list v-for="group in filteredGroups" :key="group.title">
      <v-list-group
        v-model="group.active"
        :prepend-icon="group.icon"
        :append-icon="appendIcon(group)"
      >
        <v-list-item slot="activator" :to="group.to" style="padding-left: 0; font-weight:700">
          <v-list-item-content>
            <v-list-item-title style="transition-duration: 0.1s">{{ group.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-for="item in group.items" :key="item.title" :to="item.to">
          <v-list-item-icon>
            <v-icon>mdi-circle-medium</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-text="item.title"></v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import userMod from "../../user/store/constants";
import AuthLevels from "../utils/authlevels";
import { mapGetters } from "vuex";
import {
  VIcon,
  VList,
  VListGroup,
  VListItem,
  VListItemContent,
  VListItemIcon,
  VListItemTitle,
  VNavigationDrawer,
} from "vuetify/lib";

export default {
  name: "w-drawer",

  components: {
    VIcon,
    VList,
    VListGroup,
    VListItem,
    VListItemContent,
    VListItemIcon,
    VListItemTitle,
    VNavigationDrawer,
  },

  props: {
    groups: { type: Array, default: () => [] },
    isOpen: { type: Boolean, default: false },
  },

  computed: {
    ...mapGetters({
      currentUser: userMod.get.CURRENT_USER,
    }),

    drawerOpen: {
      get: function () {
        return this.isOpen;
      },
      set: function (val) {
        if (!val) {
          this.$emit("drawer-off");
        }
      },
    },

    filteredGroups() {
      return this.groups
        .map((group) => this.simplifyGroup(group, this.currentUser))
        .filter((group) => this.isGroupAllowed(group));
    },
  },

  methods: {
    appendIcon: function (group) {
      if (group.to) {
        return " ";
      }
    },

    isItemAllowed: function (item, user) {
      const authLvl = this.$router.resolve({ path: item.to }).route.meta
        .authLevel;
        console.log(authLvl);
        console.log(user);
      return (
        authLvl === AuthLevels.OPEN ||
        (user && (authLvl === AuthLevels.USER || user.authLevel >= authLvl))
      );
    },

    simplifyGroup(group, user) {
      if (group.items && group.items.length > 0) {
        return {
          title: group.title,
          icon: group.icon,
          items: group.items.filter((item) => this.isItemAllowed(item, user)),
        };
      }
      return group;
    },

    isGroupAllowed(group) {
      if (!group.items) {
        return true;
      }
      return group.items.length > 0;
    },
  },
};
</script>

<style></style>

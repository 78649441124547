import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import colors from 'vuetify/lib/util/colors'


Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: colors.brown,
				secondary: colors.grey.lighten2,
				accent: colors.brown.lighten2,
			},
		},
	},
	icons: {
		iconfont: "mdi", // default - only for display purposes
	},
});
